<template>
<div>

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>

<div>
  <div class="pa-5 secondary elevation-5 bg-accent-card">
    <p class="mt-5 display-1">Gallery
    </p>
  </div>
</div>  

<v-container fluid style="margin-top: -200px">

    <v-form @submit.prevent="Upload()">
    <v-card class="elevation-5 mb-5">
        <v-card-title>Add To Galley</v-card-title>
        <v-card-text>
            <!-- file upload -->
            <v-file-input solo v-model="files" color="primary" single-line label="Upload Image"
            prepend-icon="mdi-google-photos" accept="image/*">
            </v-file-input>
            <v-text-field solo label="About The Picture..." prepend-icon="mdi-heart" 
                v-model.trim="Title" required></v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-center py-5">
            <v-btn type="submit" :disabled="disbtn" absolute bottom fab class="elevation-10 white--text pink">
                <v-icon :class="{ 'mdi-spin' : disbtn }">mdi-cloud-upload</v-icon>
            </v-btn>
        </v-card-actions>

        <!-- show overlay when add item -->
        <v-fade-transition>
        <v-overlay absolute :value="disbtn" opacity="0.2">
            <v-progress-circular color="yellow" indeterminate width="15" size="100"></v-progress-circular>
        </v-overlay>
        </v-fade-transition>

    </v-card><br>
    </v-form>

    <v-card class="elevation-5 my-5 pb-5">

    <v-card-title><span class="title">Recent Images</span></v-card-title>
    
    <v-card-text v-if="!GalleryData || GalleryData.length == 0" class="py-5 text-center">
        <p class="title">Sorry, No Images To Show!</p>
    </v-card-text>

    <v-card-text v-if="GalleryData" class=" text-center">
     <v-row>
        <v-col cols="12" sm="12" md="6" class="mb-5" v-for="image in GalleryData" :key="image.addedAt.toString()">
        <v-card class="my-1">
            <v-img :src="image.imageUrl" class="white--text align-end" style="height: 300px;" rel="preload">
                <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
                    </v-row>
                </template>
                <div class="pa-1" style="background-color: rgba(0,0,0,0.4);" v-if="image.title != ''">
                    <p class="d-flex align-start pt-5 pl-2">{{image.title}}</p>
                </div>
            </v-img>
            <v-card-actions class="d-flex align-center justify-center py-5">
                <v-btn dark absolute @click="$router.push('/gallerydetails/' + image.galleryslug)" 
                        bottom fab class="elevation-10 secondary">
                    <v-icon>mdi-eye</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-col>
    </v-row>
    </v-card-text>

    </v-card>

</v-container>

</div>
</template>

<script>
// imports
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/storage";

// scripts
export default {

    name: 'GalleryHome',

    data(){return{
        files: null,disbtn: false,notificationToggle: false,notificationMsg: 'hai',
        notificationType: 'hui',Title: '',
    }},

    computed: {
        GalleryData(){
            return this.$store.getters.GetGallery;
        }
    },

    methods: {

        async Upload(){
             // vars
            const dateObj = new Date();
            let imageFile;let imageFileName;let ext;let imageUrl;let key;let task;

            if(!this.BlankValidation()){return false;}this.disbtn = true;

            // check if title exists
            this.Title = this.Title != '' ? this.Title : '';

            // create a unique slug
            let gallerySlug = dateObj.getMilliseconds() + '-kalalaya-academy-' +
                        Math.floor(Math.random() * 107477);  + dateObj.getSeconds().toString();


            await firebase.firestore().collection("gallery").add({
                "galleryslug": gallerySlug,"title": this.Title,"imageUrl": '',
                "addedAt": firebase.firestore.FieldValue.serverTimestamp()
            }).then((doc) => {

                key = doc.id;
                if(this.files !== null) {

                    // actual add file
                    imageFile = this.files;
                    // get filename
                    imageFileName = imageFile.name;
                    // get extention of filename
                    ext = imageFileName.slice(imageFileName.lastIndexOf('.'));
                    // get a reference to store image in firebase storage
                    let storageRef = firebase.storage().ref('gallery/' + key + ext);
                    // upload image
                    storageRef.put(imageFile).then((snapshot) => {
                        // get download url and update db
                        snapshot.ref.getDownloadURL().then((downloadURL) => {
                            firebase.firestore().collection('gallery').doc(key).update({ imageUrl: downloadURL})
                            .then((res) => { 
                                this.files = null;this.disbtn = false;this.Title = '';
                                this.notificationService("Image Uploaded!", "success");
                                this.$store.dispatch('getGallery');
                            });
                        });
                    }).catch((err) => {
                        // spit out error
                        this.disbtn = false;   
                        this.notificationService("Server Error, Image Upload Failed, Try After Some Time", "red darken-1");
                    });
                }

            }).catch((err) => {
                // spit out error
                this.disbtn = false;   
                this.notificationService("Server Error, Image Upload Failed, Try After Some Time", "red darken-1");
            });

        },

        // check if all fileds are empty   
        BlankValidation(){
            if(this.files){
              if(this.files.size / 1024 >= 2048){
                  this.notificationService("File Should be Under 2MB!", "red darken-1");
                  return false;
              }else{
                  return true;
              }
            }else{
                  this.notificationService("Select An Image From FileManager!", "red darken-1");
            }
        },

        // notification service
        notificationService(msg, type){
            this.notificationType = type;
            this.notificationMsg = msg;
            this.notificationToggle = true;
        },

    }

}
</script>

<style scoped>

</style>